<template>
	<div class="buildList">
		<el-table style="width: 100%" v-loading="loading" :data="tableData" border :header-cell-style="headerCellStyle">
			<template slot="empty">
				<IsEmpty />
			</template>
			<el-table-column type="index" label="序号" width="50" align="center" />
			<el-table-column prop="projectName" label="项目名称" align="center" />
			<el-table-column prop="projectValue" label="项目金额" align="center"/>
			<el-table-column prop="date3" label="改造前" align="center"  width="200px">
				<template slot-scope="scope">
					<img v-if="scope.row.beforeFirstImg" :src="scope.row.beforeFirstImg" style="width: 100px;height: 100px;object-fit: cover" alt="">
					<span v-else>暂无图片</span>
				</template>
			</el-table-column>
			<el-table-column prop="date4" label="改造后" align="center" width="200px">
				<template slot-scope="scope">
					<img v-if="scope.row.afterFirstImg"  :src="scope.row.afterFirstImg" style="width: 100px;height: 100px;object-fit: cover;" alt="">
					<span v-else>暂无图片</span>
				</template>
			</el-table-column>
			<el-table-column prop="date4" label="操作" align="center">
				<template slot-scope="scope">
					<el-button type="text" @click="seeDetail(scope.row)">查看</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="mt20">总计：<span>{{totalValue}} </span> 元</div>
		<el-pagination
			background
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="tableParams.pageNum"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="tableParams.pageSize"
			layout="total, sizes, prev, pager, next"
			:total="total"
		>
		</el-pagination>
		<!-- 查看图片弹窗 -->
		<constructionListDetail v-if="drawerShow" :constructionListData="constructionListData" :drawerShow.sync="drawerShow"/>
	</div>
</template>

<script>
import { pageProject } from '@/api/bedManage'
import constructionListDetail from "./constructionListDetail";
export default {
	// 床位详情--建设清单
	name: 'buildList',
	components: {
		constructionListDetail
	},
	props: {
		bedId: {
			type: [String, Number],
			default: '',
		},
	},
	data() {
		return {
			constructionListData: null, //建设清单详情数据
			drawerShow: false, //建设清单详情
			totalValue: '', //总价格
			imgList: [],
			imageVisible: false,
			loading: false,
			tableData: [],
			tableParams: {
				pageSize: 10,
				pageNum: 1,
			},
			total: 30,
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		//查看建设清单详情
		seeDetail(row) {
			this.drawerShow = true
			this.constructionListData = row
		},
		handleSizeChange(pageSize) {
			this.tableParams.pageSize = pageSize
			this.tableParams.pageNum = 1
			this.getList()
		},
		handleCurrentChange(pageNum) {
			this.tableParams.pageNum = pageNum
			this.getList()
		},
		getList() {
			this.loading = true
			pageProject({ ...this.tableParams, pensionBedId: this.bedId })
				.then((res) => {
					let itemsData = res.data.items
					console.log(itemsData)
					let totalMon = 0
						itemsData.forEach(item => {
						item.beforeFirstImg = item.beforeImgs.length ? item.beforeImgs[0].value : ''
						item.afterFirstImg =  item.afterImgs.length ? item.afterImgs[0].value : ''
						totalMon = totalMon + item.projectValue*1
					})
					this.tableData = itemsData
					this.total = res.data.counts
					this.totalValue = totalMon
					this.loading = false
				})
				.catch(() => {
					this.tableData = []
					this.total = 0
					this.loading = false
				})
		},
		// 查看图片
		seeImage(imgs) {
			if (imgs && imgs.length > 0) {
				const imgList = imgs.map((item) => {
					return { url: item.value }
				})
				this.imgList = imgList
				this.imageVisible = true
			} else {
				this.$message.info('暂无图片')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.buildList {
}

.mt20 {
	margin-top: 20px;
	text-align: right;
	span {
		color: #FF4242;
	}
}
</style>
